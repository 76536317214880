export let commonFilterData = [
  {
    lable: "State",
    name: "state",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "District",
    name: "district",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "Block",
    name: "block",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },

  {
    lable: "Village",
    name: "village",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "Purpose",
    name: "category",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "NGO Name",
    name: "ngoName",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "FO Name",
    name: "foName",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "Sakhi Name",
    name: "sakhiName",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "Meeting Date From",
    name: "from",
    type: "date",
    multiple: false,
    placeholder: "Select",
  },
  {
    lable: "Meeting Date To",
    name: "to",
    type: "date",
    multiple: false,
    placeholder: "Select",
  },
  {
    lable: "Onboarding Year",
    name: "year",
    type: "date",
    multiple: false,
    placeholder: "Select",
  },
];

export const getLineOptions = (xScaleTitle, yScaleTitle) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.3,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: yScaleTitle,
        },
        ticks: {
          min: 0,
          font: { size: 8 }
        },
      },
      x: {
        offset: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: xScaleTitle,
        },
        ticks: {
          font: { size: 8 }
        },
      },
    },
    layout: {
      padding: {
        right: 10
      }
    }
  };
};
export const getPieOptions = () => ({
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
    callbacks: {
      label: (context) => {
        const label = context.dataset.label + " - ";
        return label + context.formattedValue;
      },
      title: () => { }
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      formatter: (value, categories) => {
        let sum = 0;
        const dataArr = categories.chart.data?.datasets[0].data;
        dataArr.map((data) => sum += data);
        const percentage = (value * 100 / sum).toFixed(1);
        return Number(percentage) ? percentage + "%" : "";
      },
      color: "#fff",
      anchor: 'center',
      offset: -20,
      align: "start"
    },
  },
});

export const ChartConfig = [
  {
    title: "Overall Transaction Status",
    logic: "Total number of transactions till date under this NGO.",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: ["Scheme", "Services", "Awareness"],
      datasets: [
        {
          label: "",
          data: [300, 50, 100],
          backgroundColor: ["#1A3E77", "#95B3CC", "#D53814"],
          //   hoverOffset: 4s
        },
      ],
    },
    type: "pie",
    options: getPieOptions()
  },
  {
    title: "Remuneration Till Date",
    logic: "Total of remuneration purpose wise, month wise under this NGO",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
        },
      ],
    },
    type: "line",
    options: getLineOptions("Month", "Value")
  },
  {
    title: "Average Transactions Per Month",
    logic: "Total transactions per month/total number of sakhis under this NGO",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
        },
      ],
    },
    type: "line",
    options: getLineOptions("Month", "Value"),
  },
  {
    title: "Evidence Per Month",
    logic: "Count of  transactions which have evidence and are approved by NGO/Admin",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
        },
      ],
    },
    type: "line",
    options: getLineOptions("Month", "Value")
  },
  {
    title: "Beneficiaries Reached By Month",
    logic: "Total unique beneficiaries under this NGO - Purpose, Gender, SHG/Non-SHG",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
        },
      ],
    },
    type: "line",
    options: getLineOptions("Month", "Value"),
  },
  {
    title: "Comparison",
    logic: "",
    legend: [
      {
        name: "Rajasthan",
        color: "#D53814",
      },
      {
        name: "Assam",
        color: "#1A3E77",
      },
    ],
    data: {
      labels: ['Rajasthan', 'Assam'],
      datasets: [
        {
          label: "",
          data: [50, 300],
          backgroundColor: ["#D53814", "#1A3E77"],
          //   hoverOffset: 4s
        },
      ],
    },
    type: "pie",
    options: getPieOptions()
  },
  {
    title: "Average Sakhi Revenue Per Month",
    logic: "Total of remuneration where evidence was provided/count of sakhis providing evidence and approved by NGO/Admin.",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
        },
      ],
    },
    type: "line",
    options: getLineOptions("Month", "Value"),
  },
  {
    title: "5 Performing Blocks",
    logic: "Total income, total schemes, services (Block wise)",

  },
  {
    title: "5 Best Performing Sakhis",
    logic: "Total income, total schemes, services (Sakhi wise)",
  },
  {
    title: "Average. Evidence Revenue Per Month",
    logic: "Total of remuneration where evidence was provided/count of sakhis providing evidence and approved by NGO/Admin",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
        },
      ],
    },
    type: "line",
    options: getLineOptions("Month", "Value"),
  },
];

