import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import PublicLayout from 'src/layouts/PublicLayout';
import MainLayout from 'src/layouts/MainLayout';
import NotFoundView from 'src/views/errors/NotFoundPage';
import Login from './views/auth/Login';


import UnauthorizedPage from './views/errors/UnauthorizedView';
import { useRoutes } from 'react-router-dom';
import SakhiTransaction from './views/sakhi_transactions';
import SakhiTransactionView from './views/sakhi_transactions/sakhi_transactions_view';
import TheFODetailsView from './views/fo_details';
import FODetailsCRUDFormView from './views/fo_details/FODetailsCRUDForm';
import CenterDetailsView from './views/center_details';
import CenterDetailsCRUDFormView from './views/center_details/CenterDetailsCRUDFrom';
import CenterFoMappingView from './views/mapping/CreatingFoMapping';
import CenterFoMappingCRUDFormView from './views/mapping/CreatingFoMapping/CenterFoMappingCRUDFrom';
import FoSakhiMappingView from './views/mapping/FOSakhiMapping';
import FoSakhiMappingCRUDFormView from './views/mapping/FOSakhiMapping/FoSakhiMappingCRUDFrom';
import AdminMappingView from './views/adminMapping';
import AdminMappingCRUDFormView from './views/adminMapping/AdminMapppingCRUDForm';
import AdminUsersView from './views/users';
import AdminUsersCRUDFormView from './views/users/AdminUsersCRUDForm';
import MastersView from './views/master';
import MainDashboard from './views/DashboardMain';
import SakhiDetailsView from './views/sakhi_details';
import AdminTrainingKitView from './views/trainingKit';
import TrainingKitCRUDFormView from './views/trainingKit/TrainingKitCRUDFrom';
import SakhiDetailsCRUDFormView from './views/sakhi_details/SakhiDetailsCRUDForm';
import SettingView from './views/setting';
import ReportsView from './views/reports';
import FeedbackView from './views/feedback';
import Target from './views/masterTarget';
import Budget from './views/budget';
import DownloadQueue from './views/downloadQueue';



const Routes = () => {
  const routes = [
    {
      path: 'app',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <MainDashboard /> },
        { path: "download-queue-list", element: <DownloadQueue /> },
        {
          path: 'sakhitransactions',
          element: <SakhiTransaction />,
        },
        {
          path: 'sakhitransactions/view/:id',
          element: <SakhiTransactionView />
        },
        {
          path: 'sakhitransactions/edit/:id',
          element: <SakhiTransactionView />
        },
        {
          path: 'fodetails',
          element: <TheFODetailsView />
        },
        {
          path: 'fodetails/create',
          element: <FODetailsCRUDFormView />
        },
        {
          path: 'fodetails/edit/:id',
          element: <FODetailsCRUDFormView />
        },
        {
          path: 'fodetails/view/:id',
          element: <FODetailsCRUDFormView />
        },
        {
          path: 'fodetails/delete/:id',
          element: <FODetailsCRUDFormView />
        },
        {
          path: 'centerdetails',
          element: <CenterDetailsView />
        },
        {
          path: 'centerdetails/create',
          element: <CenterDetailsCRUDFormView />
        },
        {
          path: 'centerdetails/edit/:id',
          element: <CenterDetailsCRUDFormView />
        },
        {
          path: 'centerdetails/view/:id',
          element: <CenterDetailsCRUDFormView />
        },
        {
          path: 'centerdetails/delete/:id',
          element: <CenterDetailsCRUDFormView />
        },
        {
          path: 'sakhidetails',
          element: <SakhiDetailsView />
        },
        {
          path: 'sakhidetails/view/:id',
          element: <SakhiDetailsCRUDFormView />
        },
        {
          path: 'reports',
          element: <ReportsView />
        },
        {
          path: 'mapping/centerfomapping',
          element: <CenterFoMappingView />
        },
        {
          path: 'mapping/centerfomapping/create',
          element: <CenterFoMappingCRUDFormView />
        },
        {
          path: 'mapping/centerfomapping/edit/:id',
          element: <CenterFoMappingCRUDFormView />
        },
        {
          path: 'mapping/centerfomapping/view/:id',
          element: <CenterFoMappingCRUDFormView />
        },
        {
          path: 'mapping/centerfomapping/delete/:id',
          element: <CenterFoMappingCRUDFormView />
        },
        {
          path: 'mapping/fosakhimapping',
          element: <FoSakhiMappingView />
        },
        {
          path: 'mapping/fosakhimapping/create',
          element: <FoSakhiMappingCRUDFormView />
        },
        {
          path: 'mapping/fosakhimapping/edit/:id',
          element: <FoSakhiMappingCRUDFormView />
        },
        {
          path: 'mapping/fosakhimapping/view/:id',
          element: <FoSakhiMappingCRUDFormView />
        },
        {
          path: 'mapping/fosakhimapping/delete/:id',
          element: <FoSakhiMappingCRUDFormView />
        },
        {
          path: 'admin/mapping',
          element: <AdminMappingView />
        },
        {
          path: 'admin/mapping/create',
          element: <AdminMappingCRUDFormView />
        },
        {
          path: 'admin/mapping/edit/:id',
          element: <AdminMappingCRUDFormView />
        },
        {
          path: 'admin/mapping/view/:id',
          element: <AdminMappingCRUDFormView />
        },
        {
          path: 'admin/mapping/delete/:id',
          element: <AdminMappingCRUDFormView />
        },
        {
          path: 'admin/users',
          element: <AdminUsersView />
        },
        {
          path: 'admin/users/create',
          element: <AdminUsersCRUDFormView />
        },
        {
          path: 'admin/users/view/:id',
          element: <AdminUsersCRUDFormView />
        },
        {
          path: 'admin/users/edit/:id',
          element: <AdminUsersCRUDFormView />
        },
        {
          path: 'admin/users/delete/:id',
          element: <AdminUsersCRUDFormView />
        },
        {
          path: 'admin/reports',
          element: <ReportsView />
        },
        {
          path: 'admin/masters',
          element: <MastersView />,
        },
        {
          path: "admin/master/target",
          element: <Target />
        },
        {
          path: 'admin/trainingkit',
          element: <AdminTrainingKitView />
        },
        {
          path: 'admin/trainingkit/create',
          element: <TrainingKitCRUDFormView />
        },
        {
          path: 'admin/trainingkit/view/:id',
          element: <TrainingKitCRUDFormView />
        },
        {
          path: 'admin/trainingkit/edit/:id',
          element: <TrainingKitCRUDFormView />
        },
        {
          path: 'admin/trainingkit/delete/:id',
          element: <TrainingKitCRUDFormView />
        },
        {
          path: 'admin/setting',
          element: <SettingView />
        },
        {
          path: 'feedback',
          element: <FeedbackView />
        },
        {
          path: 'admin/budget',
          element: <Budget />,
        },
        {
          path: 'expense',
          element: <Budget />,
        },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'unauthorized-page', element: <UnauthorizedPage /> },
      ],
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/public',
      element: <PublicLayout />,
      children: [{ path: 'dashboard', element: "" }],
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '404', element: <NotFoundView /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
  ];
  return useRoutes(routes);
}
export default Routes;