import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
  Zoom,
} from "@material-ui/core";
import {
  CloudDownload,
  FilterList,
  PieChartOutlined,
  SignalCellularAlt,
  Timeline,
  InfoOutlined,
  ArrowRightAlt
} from "@material-ui/icons";
import moment from "moment";
import React, { useMemo, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LineChart from "src/components/CommonComponents/ChartComponent";
import { purposeListAction } from "src/Redux/Actions/PurposeAction";
import { stateListAction } from "src/Redux/Actions/StateAction";
import { fetchWrapper } from "src/services/http_requests";
import theme from "src/theme";
import { paramsUrl } from "src/utils/halper";
import { loadData } from "src/utils/sessionStorage";
import { ChartConfig, getLineOptions } from "./utils";
import { ngoListAction } from "src/Redux/Actions/NgoAction";
import ScrollBar from "react-perfect-scrollbar";
import MapView from "./AdminDashboard/Map";
import {
  AdminChartLabels,
  AdminDetailsBox,
  FoChartLabels,
  genderGroupLegend,
  legendActiveColor,
  legendDeactiveColor,
  shgGroupLegend,
  titleButton,
  titleLeftButtonActiveStyle,
  titleLeftButtonDeactiveStyle,
  titleRightButtonActiveStyle,
  titleRightButtonDeactiveStyle,
} from "./Conistance";
import IndicatorsComponent from "./IndicatorsComponent";
import TopWorstBlock from "./TopWorstBlock";
import BlockComponent from "./BlockComponent";
import CommonFilter2 from "src/components/CommonComponents/CommonFilter2";
import mapPng from 'src/assets/images/map.png';
import { useNavigate } from "react-router";
import { isEqual } from "lodash";

const Base_Url = process.env.REACT_APP_API_URL;

const indicatorKey = {
  "Total Onboarded Sakhis": "total_onboarded",
  "Total Schemes": "scheme_count",
  "Total Transactions": "total_application",
  "Total Active Sakhis": "total_active_sakhi",
  "Total Inactive Sakhis": "total_inactive_sakhi",
  "Total Earning Sakhis": "total_earning_sakhi",
  "Total Income of Sakhis": "total_income_sakhi",
  "Average Income of Sakhis": "avg_income",
  "Median Income of Sakhis": "median",
  "Total Applications": "total_application",
  "Total Services": "services_count",
  "Total Awareness": "awareness_count",
  "Unique Beneficiaries Reached": "beneficiary_count",
  "Total Villages Reached": "village_count",
  "Total SHGs Covered": "total_shg",
};

function MainDashboard() {
  const userData = loadData("loginInfo")?.data;
  const role_id = userData?.role_id;
  const userInfo = loadData("loginInfo");
  const token = userInfo?.token;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [classN, setClassN] = useState(false);
  const [classGrid, setClassGrid] = useState(false);
  const [scroll, setScroll] = useState(false)
  const [filterData, setFilterData] = useState({
    // level: 'state',
    state_id: "",
    district_id: "",
    block_id: "",
    village_id: "",
    purpose_id: "",
    scheme_id: "",
    from: "",
    to: "",
    ngo_id: "",
    sakhi_id: "",
    fo_id: "",
    year: "",
    calendar_year: ""
  });
  const [showFilter, setShowFilter] = useState(false);
  const [activeIndicator, setActiveIndicator] = useState("Total Active Sakhis");
  const [overallGraph, setOverallGraph] = useState({});
  const [worstFiveBlockTransaction, setworstFiveBlockTransaction] = useState([]);
  const [worstFiveSakhiTransaction, setworstFiveSakhiTransaction] = useState([]);
  const [topFiveBlockTransaction, setTopFiveBlockTransaction] = useState([]);
  const [topFiveBlockSakhi, setTopFiveBlockSakhi] = useState([]);
  const [monthWiseUniqueBeneficiary, setMonthWiseUniqueBeneficiary] = useState([]);
  const [indicatorLoading, setIndicatorLoading] = useState(true);
  const [active, setActive] = useState({
    person: true,
    gender: false,
    shg: false,
  });
  const [indicatorData, setIndicatorData] = useState({
    avg_income: 0,
    beneficiary_count: 0,
    median: 0,
    total_active_sakhi: 0,
    total_application: 0,
    total_earning_sakhi: 0,
    total_inactive_sakhi: 0,
    total_income_sakhi: 0,
    total_shg: 0,
    village_count: 0,
  });
  const [type, setType] = useState({
    beneficiariesReachedByMonth: "bar",
    renumerationTillDate: "bar",
    averageTransactionPerMonth: "bar",
    evidencePerMonth: "bar",
    comparsion: "pie",
    averageSakhiRevenuePerMonth: "bar",
    sakhisProvidingEvidencesPerMonth: "bar",
  });
  const [clicktopFiveBlockTransaction, setclicktopFiveBlockTransaction] = useState("top");
  const [clicktopFiveBlockSakhi, setclicktopfiveBlockSakhi] = useState("top");
  const [select, setSelect] = useState({
    beneficiariesReachedByMonth: "All",
    comparison: 'All',
    renumerationTillDate: "All",
    averageTransactionPerMonth: "All",
    evidencePerMonth: "All",
    averageSakhiRevenuePerMonth: "All",
    sakhisProvidingEvidencesPerMonth: "All",
  });
  const statesList = useSelector((state) => state.statesList);
  const { states } = statesList;
  const [comparisonSelect, setComparisonSelect] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("state");
  const [mapStateData, setMapStateData] = useState([]);
  const [mapAllData, setMapAllData] = useState([]);
  const [comparisonAllData, setComparisonAllData] = useState([]);
  const [comparisonData, setComparisonData] = useState([]);
  const [compariseChart, setCompariseChart] = useState([]);

  const fetchAllApis = (filterDataObj) => {
    getIndicatorData(filterDataObj);
    getOverall(filterDataObj);
    getMapStateData({ level: selectedLevel, ...filterDataObj });
    getCompersionData({ ...filterDataObj, level: selectedLevel });
    getCompariseChart({ ...filterDataObj, level: selectedLevel });
    if ([1]?.includes(role_id)) {
      getWorstFiveBlockTransaction(filterDataObj);
      getWorstFiveSakhiTransaction(filterDataObj);
      getTopFiveBlockTransaction(filterDataObj);
      getTopFiveBlockSakhi(filterDataObj);
      return;
    }
    if ([2]?.includes(role_id)) getMonthWiseUniqueBeneficiary(filterDataObj);
  }

  const handleFilterSearch = (filters = filterData) => {
    fetchWrapper.finishPendingRequests();
    if (filters?.state_id) {
      const stateName = states?.data?.find(val => Number(val.id) === Number(filters?.state_id))?.name;
      setSelectedState(stateName || "");
    }
    fetchAllApis(filters);
  };

  const handleRestFilter = () => {
    const resetedFilter = {
      state_id: "",
      district_id: "",
      block_id: "",
      village_id: "",
      purpose_id: "",
      scheme_id: "",
      from: "",
      to: "",
      ngo_id: "",
      sakhi_id: "",
      fo_id: "",
      year: "",
    };
    setFilterData(resetedFilter)
    if (selectedLevel === "state") handleFilterSearch(resetedFilter);
    else {
      setSelectedState('');
    }
  };

  const getMapStateData = async (query) => {
    const filter = {
      level: query?.level,
      fetched: "All",
      state_id: query?.state_id,
      district_id: query?.district_id,
      block_id: query?.block_id,
      village_id: query?.village_id,
      purpose_id: query?.purpose_id,
      scheme_id: query?.scheme_id,
      sakhi_id: query?.sakhi_id,
      ngo_id: query?.ngo_id,
      fo_id: query?.fo_id,
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
      year: query?.year,
      calendar_year: query?.calendar_year ? moment(query.calendar_year).format("YYYY") : ""
    };
    const url = `${Base_Url}/dashboard/listMapData${paramsUrl(filter)}`;
    try {
      const data = await fetchWrapper.get(url);
      setMapStateData(data?.data);
    } catch (error) {
      toast.error(error);
    }
  };
  const getCompersionData = async (query) => {
    const filter = {
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
    };
    const url = `${Base_Url}/dashboard/listMapData${paramsUrl(filter)}`;
    try {
      const data = await fetchWrapper.get(url);
      setComparisonData(data?.data)
    } catch (error) {
      toast.error(error);
    }
  };
  const getIndicatorData = async (query) => {
    setIndicatorLoading(true);
    const filter = {
      fetched: "All",
      state_id: query?.state_id,
      district_id: query?.district_id,
      block_id: query?.block_id,
      village_id: query?.village_id,
      purpose_id: query?.purpose_id,
      scheme_id: query?.scheme_id,
      sakhi_id: query?.sakhi_id,
      ngo_id: query?.ngo_id,
      fo_id: query?.fo_id,
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
      year: query?.year,
      calendar_year: query?.calendar_year ? moment(query.calendar_year).format("YYYY") : ""
    };
    const medianUrl = `${Base_Url}/dashboard/median${paramsUrl(filter)}`;
    const url = `${Base_Url}/dashboard/indicator${paramsUrl(filter)}`;
    try {
      const { data } = await fetchWrapper.get(url);
      const { data: data2 } = await fetchWrapper.get(medianUrl);

      Promise.all([data, data2]).then(value => {
        let finalData = {};
        value?.map(item => {
          let dataObj = { ...item };

          finalData = { ...finalData, ...dataObj[0] };
          return [];
        });
        setIndicatorData(finalData);
        setIndicatorLoading(false);
      })
    } catch (error) {
      toast.error(error);
      setIndicatorLoading(false);
    }
  };
  const getOverall = async (query) => {
    const filter = {
      fetched: "All",
      state_id: query?.state_id,
      district_id: query?.district_id,
      block_id: query?.block_id,
      village_id: query?.village_id,
      purpose_id: query?.purpose_id,
      scheme_id: query?.scheme_id,
      sakhi_id: query?.sakhi_id,
      ngo_id: query?.ngo_id,
      fo_id: query?.fo_id,
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
      year: query?.year,
      calendar_year: query?.calendar_year ? moment(query.calendar_year).format("YYYY") : ""
    };
    const url = `${Base_Url}/dashboard/overall_graph${paramsUrl(filter)}`;
    try {
      const data = await fetchWrapper.get(url);
      setOverallGraph(data?.data);
    } catch (error) {
      toast.error(error);
    }
  };
  const getCompariseChart = async (query) => {
    const filter = {
      type: 'MOTH_WISE_DATA',
      level: query.level,
      fetched: "All",
      state_id: query?.state_id,
      district_id: query?.district_id,
      block_id: query?.block_id,
      village_id: query?.village_id,
      purpose_id: query?.purpose_id,
      scheme_id: query?.scheme_id,
      sakhi_id: query?.sakhi_id,
      ngo_id: query?.ngo_id,
      fo_id: query?.fo_id,
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
      year: query?.year,
      calendar_year: query?.calendar_year ? moment(query.calendar_year).format("YYYY") : ""
    };
    const url = `${Base_Url}/dashboard/monthWiseComparisonData${paramsUrl(filter)}`;
    try {
      const data = await fetchWrapper.get(url);
      setCompariseChart(data?.data);
    } catch (error) {
      toast.error(error);
    }
  };
  const getWorstFiveBlockTransaction = async (query) => {
    const filter = {
      fetched: "All",
      state_id: query?.state_id,
      district_id: query?.district_id,
      block_id: query?.block_id,
      village_id: query?.village_id,
      purpose_id: query?.purpose_id,
      scheme_id: query?.scheme_id,
      sakhi_id: query?.sakhi_id,
      ngo_id: query?.ngo_id,
      fo_id: query?.fo_id,
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
      year: query?.year,
      calendar_year: query?.calendar_year ? moment(query.calendar_year).format("YYYY") : ""
    };
    const url = `${Base_Url}/dashboard/wostFiveBlockTransaction${paramsUrl(
      filter
    )}`;
    try {
      const data = await fetchWrapper.get(url);
      setworstFiveBlockTransaction(data?.data);
    } catch (error) {
      toast.error(error);
    }
  };
  const getWorstFiveSakhiTransaction = async (query) => {
    const filter = {
      fetched: "All",
      state_id: query?.state_id,
      district_id: query?.district_id,
      block_id: query?.block_id,
      village_id: query?.village_id,
      purpose_id: query?.purpose_id,
      scheme_id: query?.scheme_id,
      sakhi_id: query?.sakhi_id,
      ngo_id: query?.ngo_id,
      fo_id: query?.fo_id,
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
      year: query?.year,
      calendar_year: query?.calendar_year ? moment(query.calendar_year).format("YYYY") : ""
    };
    const url = `${Base_Url}/dashboard/worstFiveBlockSakhi${paramsUrl(
      filter
    )}`;
    try {
      const data = await fetchWrapper.get(url);
      setworstFiveSakhiTransaction(data?.data);
    } catch (error) {
      toast.error(error);
    }
  };
  const getTopFiveBlockTransaction = async (query) => {
    const filter = {
      fetched: "All",
      state_id: query?.state_id,
      district_id: query?.district_id,
      block_id: query?.block_id,
      village_id: query?.village_id,
      purpose_id: query?.purpose_id,
      scheme_id: query?.scheme_id,
      sakhi_id: query?.sakhi_id,
      ngo_id: query?.ngo_id,
      fo_id: query?.fo_id,
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
      year: query?.year,
      calendar_year: query?.calendar_year ? moment(query.calendar_year).format("YYYY") : ""
    };
    const url = `${Base_Url}/dashboard/topFiveBlockTransaction${paramsUrl(
      filter
    )}`;
    try {
      const data = await fetchWrapper.get(url);
      setTopFiveBlockTransaction(data?.data);
    } catch (error) {
      toast.error(error);
    }
  };
  const getTopFiveBlockSakhi = async (query) => {
    const filter = {
      fetched: "All",
      state_id: query?.state_id,
      district_id: query?.district_id,
      block_id: query?.block_id,
      village_id: query?.village_id,
      purpose_id: query?.purpose_id,
      scheme_id: query?.scheme_id,
      sakhi_id: query?.sakhi_id,
      ngo_id: query?.ngo_id,
      fo_id: query?.fo_id,
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
      year: query?.year,
      calendar_year: query?.calendar_year ? moment(query.calendar_year).format("YYYY") : ""
    };
    const url = `${Base_Url}/dashboard/topFiveBlockSakhi${paramsUrl(filter)}`;
    try {
      const data = await fetchWrapper.get(url);
      setTopFiveBlockSakhi(data?.data);
    } catch (error) {
      toast.error(error);
    }
  };
  const getMonthWiseUniqueBeneficiary = async (query) => {
    const filter = {
      fetched: "All",
      state_id: query?.state_id,
      district_id: query?.district_id,
      block_id: query?.block_id,
      village_id: query?.village_id,
      purpose_id: query?.purpose_id,
      scheme_id: query?.scheme_id,
      sakhi_id: query?.sakhi_id,
      ngo_id: query?.ngo_id,
      fo_id: query?.fo_id,
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
      year: query?.year,
      calendar_year: query?.calendar_year ? moment(query.calendar_year).format("YYYY") : ""
    };
    const url = `${Base_Url}/dashboard/monthWiseUniqueBeneficiary${paramsUrl(
      filter
    )}`;
    try {
      const data = await fetchWrapper.get(url);
      setMonthWiseUniqueBeneficiary(data?.data);
    } catch (error) {
      toast.error(error);
    }
  };
  const prevCsvQuery = useRef({});
  const getCsvDownloadData = async (query) => {

    const filter = {
      token: token,
      fetched: "All",
      state_id: query?.state_id,
      district_id: query?.district_id,
      block_id: query?.block_id,
      village_id: query?.village_id,
      purpose_id: query?.purpose_id,
      scheme_id: query?.scheme_id,
      sakhi_id: query?.sakhi_id,
      ngo_id: query?.ngo_id,
      fo_id: query?.fo_id,
      from: query?.from ? moment(query?.from).format("YYYY-MM-DD") : "",
      to: query?.to ? moment(query?.to).format("YYYY-MM-DD") : "",
      year: query?.year,
      calendar_year: query?.calendar_year ? moment(query.calendar_year).format("YYYY") : ""
    };
    if (isEqual(prevCsvQuery.current || {}, filter)) {
      toast.error("A download request with same filter is already executed")
      return;
    }
    prevCsvQuery.current = filter;
    const url = `${Base_Url}/download_sheet/request${paramsUrl(filter)}`;
    try {
      const data = await fetchWrapper.get(url, { responseType: "arraybuffer" });
      if (data.success) toast.success(data.message || "Download request is in the queue.");
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(stateListAction({ fetched: "All" }));
      dispatch(ngoListAction({ fetched: "All" }));
      dispatch(purposeListAction({ fetched: "All" }));
      setActiveIndicator("Total Onboarded Sakhis");
      setComparisonSelect("Total Onboarded Sakhis");
    }

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (role_id) {
      [1]?.includes(role_id) &&
        localStorage.setItem(
          "selectedUser",
          JSON.stringify({ ngo: true, fo: false, sakhi: false })
        );
      [1]?.includes(role_id) && localStorage.setItem("pageInfo", 1);
    }
  }, [role_id]);

  useEffect(() => {
    setTimeout(() => {
      setClassN(true)
    }, 400);
    setTimeout(() => {
      setClassGrid(true)
    }, 200);
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10)
    })
    window.scrollTo(0, 0);
    localStorage.setItem("currentNav", 0);
    return () => fetchWrapper?.finishPendingRequests();
  }, []);


  const filterMapData = useMemo(() => {
    let mount = true;
    if (mount) {
      const filterState = {
        data: mapStateData?.map((state) => ({
          state_name: state['name']?.trim()?.charAt(0).toUpperCase() + state['name']?.trim()?.toLowerCase()?.slice(1),
          total: Number(state[indicatorKey[activeIndicator]]),
          color: `#${Math.floor(Math.random() * 16777215).toString(16)}`
        })),
        showPercentage: true,
        total: mapStateData
          ?.map((val) => Number(val[[indicatorKey[activeIndicator]]]))
          ?.reduce((a, c) => a + c, 0),
      };
      const comparisonFilterState = {
        data: comparisonData?.map((state) => ({
          state_name: state.name?.trim(),
          // state_id: state.state_id,
          total: Number(state[indicatorKey[comparisonSelect]]),
          color: `#${Math.floor(Math.random() * 16777215).toString(16)}`
        })),
        total: comparisonData
          ?.map((val) => Number(val[[indicatorKey[comparisonSelect]]]))
          ?.reduce((a, c) => a + c, 0),
      };
      setComparisonAllData(comparisonFilterState);
      setMapAllData(filterState);
    }

    return () => (mount = false);
  }, [activeIndicator,
    selectedLevel,
    comparisonSelect,
    mapStateData,
    comparisonData]);

  useEffect(() => {
    setComparisonSelect("Total Onboarded Sakhis");
  }, [mapStateData]);


  const [updataChartConfig, setUpdateChartConfig] = useState([]);

  // create charts
  useEffect(() => {
    if (Object.keys(overallGraph).length > 0 && role_id) {
      const pieGroup = overallGraph?.chart_gs?.reduce((group, product) => {
        const { name } = product;
        group[name] = group[name] ?? [];
        group[name]?.push(product);
        return group;
      }, {});

      const barGroup = overallGraph?.data?.reduce((group, product) => {
        const { name } = product;
        group[name] = group[name] ?? [];
        group[name]?.push(product);
        return group;
      }, {});


      const comparisonsGroup = compariseChart?.map(item => ({
        month: item.month || 1,
        name: item?.name?.trim(),
        total: Number(item[indicatorKey[comparisonSelect]])

      }));
      const comparisonGroup = comparisonsGroup?.reduce((group, product) => {
        const { name } = product;
        group[name] = group[name] ?? [];
        group[name]?.push(product);
        return group;
      }, {})

      const FoChartData = ChartConfig?.filter((data) =>
        (role_id === 1 ? AdminChartLabels : FoChartLabels).includes(data.title)
      );

      const chartUpdate = FoChartData?.map((val) =>
        ["Overall Transaction Status"]?.includes(val?.title) ? {
          ...val,
          legend: Object.keys(pieGroup)?.map((key) => ({
            name: key,
            color:
              key === "Scheme"
                ? "#00b0f0"
                : key === "Services"
                  ? "#a8b918"
                  : key === "Awareness"
                    ? "#1a3e77"
                    : "#328ba8",
          })),
          data: {
            labels: overallGraph?.chart_gs?.map((item) => item?.name),
            datasets: [
              {
                label: "Overall Transaction Status",
                data: overallGraph?.chart_gs?.map((item) =>
                  Number(item?.total)
                ),
                backgroundColor: Object.keys(barGroup)
                  ?.map((key) => ({
                    name: key,
                    color:
                      key === "Scheme"
                        ? "#00b0f0"
                        : key === "Services"
                          ? "#a8b918"
                          : key === "Awareness"
                            ? "#1a3e77"
                            : "#328ba8",
                  }))
                  .map((color) => color?.color),
                //   hoverOffset: 4s
              },
            ],
          },
        } : ["Remuneration Till Date"]?.includes(val?.title) ? {
          ...val,
          type: type.renumerationTillDate,
          legend: Object.keys(barGroup)?.map((key) => ({
            name: key,
            color: key === "Scheme" ? "#00b0f0"
              : key === "Services" ? "#a8b918"
                : key === "Awareness" ? "#1a3e77"
                  : "#328ba8",
          })),
          data: {
            labels: moment.monthsShort(),
            datasets: Object.keys(barGroup)
              ?.filter((key) =>
                select.renumerationTillDate === "All"
                  ? key
                  : key === select?.renumerationTillDate
              )
              ?.map((key, i) => ({
                data: barGroup[key]?.map((val) => ({
                  x: moment.monthsShort()[parseInt(val?.month) - 1],
                  y: Number(val?.remuneration_amount),
                })),
                label: key,
                borderColor: key === "Scheme" ? "#00b0f0"
                  : key === "Services" ? "#a8b918"
                    : key === "Awareness" ? "#1a3e77"
                      : "#328ba8",
                backgroundColor: key === "Scheme" ? "#00b0f0"
                  : key === "Services" ? "#a8b918"
                    : key === "Awareness" ? "#1a3e77"
                      : "#328ba8",
                fill: false,
                borderWidth: 1.5,
                borderSkipped: false,
              })),
          },
        } : ["Comparison"]?.includes(val?.title) ? {
          ...val,
          type: type.comparsion,
          legend: comparisonAllData?.data?.map((val) => ({
            name: val?.state_name,
            color:
              val?.state_name === "Assam"
                ? "#00b0f0"
                : val?.state_name === "Services"
                  ? "#a8b918"
                  : val?.state_name === "Rajasthan"
                    ? "#1a3e77"
                    : "#328ba8",
          })),
          data: {
            labels:
              type.comparsion === "pie"
                ? comparisonAllData?.data?.map((val) => val?.state_name)
                : moment.monthsShort(),
            datasets:
              type.comparsion === "pie"
                ? [
                  {
                    label: "Comparison",
                    data: comparisonAllData?.data?.map((val) =>
                      Number(val?.total)
                    ),
                    backgroundColor: ["#00b0f0", "#1a3e77"],
                    //   hoverOffset: 4s
                  },
                ]
                : Object.keys(comparisonGroup)
                  ?.filter((key) =>
                    select.comparison === "All"
                      ? key
                      : key === select?.comparison
                  )
                  ?.map((key, i) => ({
                    data: comparisonGroup[key]?.map((val) => ({
                      x: moment.monthsShort()[parseInt(val?.month) - 1],
                      y: Number(val?.total),
                    })),
                    label: key,
                    borderColor:
                      key === "Assam"
                        ? "#00b0f0"
                        : key === "Services"
                          ? "#a8b918"
                          : key === "Rajasthan"
                            ? "#1a3e77"
                            : "#328ba8",
                    backgroundColor:
                      key === "Assam"
                        ? "#00b0f0"
                        : key === "Services"
                          ? "#a8b918"
                          : key === "Rajasthan"
                            ? "#1a3e77"
                            : "#328ba8",
                    fill: false,
                    borderWidth: 1.5,
                    borderSkipped: false,
                  })),
          },
          options:
            type.comparsion === "pie"
              ? val?.options
              : getLineOptions("Month", "Value")
        } : ["Average Transactions Per Month"]?.includes(val?.title) ? {
          ...val,
          type: type?.averageTransactionPerMonth,
          legend: Object.keys(barGroup)?.map((key) => ({
            name: key,
            color:
              key === "Scheme"
                ? "#00b0f0"
                : key === "Services"
                  ? "#a8b918"
                  : key === "Awareness"
                    ? "#1a3e77"
                    : "#328ba8",
          })),
          data: {
            labels: moment.monthsShort(),
            datasets: Object.keys(barGroup)
              ?.filter((key) =>
                select.averageTransactionPerMonth === "All"
                  ? key
                  : key === select?.averageTransactionPerMonth
              )
              ?.map((key, i) => ({
                data: barGroup[key]?.map((val) => ({
                  x: moment.monthsShort()[parseInt(val?.month) - 1],
                  y: Number(val?.total),
                })),
                label: key,
                borderColor:
                  key === "Scheme"
                    ? "#00b0f0"
                    : key === "Services"
                      ? "#a8b918"
                      : key === "Awareness"
                        ? "#1a3e77"
                        : "#328ba8",
                backgroundColor:
                  key === "Scheme"
                    ? "#00b0f0"
                    : key === "Services"
                      ? "#a8b918"
                      : key === "Awareness"
                        ? "#1a3e77"
                        : "#328ba8",
                fill: false,
                borderWidth: 1.5,
                borderSkipped: false,
              })),
          },
        } : ["Beneficiaries Reached By Month"]?.includes(val?.title) ? {
          ...val,
          type: type?.beneficiariesReachedByMonth,
          legend: active?.person
            ? Object.keys(barGroup)?.map((key) => ({
              name: key,
              color:
                key === "Scheme"
                  ? "#00b0f0"
                  : key === "Services"
                    ? "#a8b918"
                    : key === "Awareness"
                      ? "#1a3e77"
                      : "#328ba8",
            }))
            : active?.gender
              ? genderGroupLegend?.map((key) => ({
                name: key,
                color:
                  key === "Male"
                    ? "#00b0f0"
                    : key === "Female"
                      ? "#a8b918"
                      : key === "Transgender"
                        ? "#1a3e77"
                        : "#328ba8",
              }))
              : active?.shg
                ? shgGroupLegend?.map((key) => ({
                  name: key,
                  color:
                    key === "SHG"
                      ? "#00b0f0"
                      : key === "Non SHG"
                        ? "#a8b918"
                        : key === "Transgender"
                          ? "#1a3e77"
                          : "#328ba8",
                }))
                : [],
          data: {
            labels: moment.monthsShort(),
            datasets: active?.person
              ? Object.keys(barGroup)
                ?.filter((key) =>
                  select.beneficiariesReachedByMonth === "All"
                    ? key
                    : key === select?.beneficiariesReachedByMonth
                )
                ?.map((key, i) => ({
                  data: barGroup[key]?.map((val) => ({
                    x: moment.monthsShort()[parseInt(val?.month) - 1],
                    y: Number(val?.beneficiary_count),
                  })),
                  label: key,
                  borderColor:
                    key === "Scheme"
                      ? "#00b0f0"
                      : key === "Services"
                        ? "#a8b918"
                        : key === "Awareness"
                          ? "#1a3e77"
                          : "#328ba8",
                  backgroundColor:
                    key === "Scheme"
                      ? "#00b0f0"
                      : key === "Services"
                        ? "#a8b918"
                        : key === "Awareness"
                          ? "#1a3e77"
                          : "#328ba8",
                  fill: false,
                  borderWidth: 1.5,
                  borderSkipped: false,
                }))
              : active?.gender
                ? genderGroupLegend
                  ?.filter((key) =>
                    select?.beneficiariesReachedByMonth === "All"
                      ? key
                      : key === select?.beneficiariesReachedByMonth
                  )
                  ?.map((key, i) => ({
                    data: monthWiseUniqueBeneficiary?.map((val) => ({
                      x: moment.monthsShort()[parseInt(val?.month) - 1],
                      y: Number(
                        ["Male"]?.includes(key)
                          ? val?.male_total
                          : ["Female"]?.includes(key)
                            ? val?.female_total
                            : val?.transgender_total
                      ),
                    })),
                    label: key,
                    borderColor:
                      key === "Male"
                        ? "#00b0f0"
                        : key === "Female"
                          ? "#a8b918"
                          : key === "Transgender"
                            ? "#1a3e77"
                            : "#328ba8",
                    backgroundColor:
                      key === "Male"
                        ? "#00b0f0"
                        : key === "Female"
                          ? "#a8b918"
                          : key === "Transgender"
                            ? "#1a3e77"
                            : "#328ba8",
                    fill: false,
                    borderWidth: 1.5,
                    borderSkipped: false,
                  }))
                : shgGroupLegend
                  ?.filter((key) =>
                    select?.beneficiariesReachedByMonth === "All"
                      ? key
                      : key === select?.beneficiariesReachedByMonth
                  )
                  ?.map((key, i) => ({
                    data: monthWiseUniqueBeneficiary?.map((val) => ({
                      x: moment.monthsShort()[parseInt(val?.month) - 1],
                      y: Number(
                        ["SHG"]?.includes(key)
                          ? val?.total_shg
                          : ["Non SHG"]?.includes(key)
                            ? val?.total_un_shg
                            : val?.transgender_total
                      ),
                    })),
                    label: key,
                    borderColor:
                      key === "SHG"
                        ? "#00b0f0"
                        : key === "Non SHG"
                          ? "#a8b918"
                          : key === "Transgender"
                            ? "#1a3e77"
                            : "#328ba8",
                    backgroundColor:
                      key === "SHG"
                        ? "#00b0f0"
                        : key === "Non SHG"
                          ? "#a8b918"
                          : key === "Transgender"
                            ? "#1a3e77"
                            : "#328ba8",
                    fill: false,
                    borderWidth: 1.5,
                    borderSkipped: false,
                  })),
          },
        } : ["Evidence Per Month"]?.includes(val?.title) ? {
          ...val,
          type: type?.evidencePerMonth,
          legend: Object.keys(barGroup)?.map((key) => ({
            name: key,
            color:
              key === "Scheme"
                ? "#00b0f0"
                : key === "Services"
                  ? "#a8b918"
                  : key === "Awareness"
                    ? "#1a3e77"
                    : "#328ba8",
          })),
          data: {
            labels: moment.monthsShort(),
            datasets: Object.keys(barGroup)
              ?.filter((key) =>
                select.evidencePerMonth === "All"
                  ? key
                  : key === select?.evidencePerMonth
              )
              ?.map((key, i) => ({
                data: barGroup[key]?.map((val) => ({
                  x: moment.monthsShort()[parseInt(val?.month) - 1],
                  y: Number(val?.evidence_per_month_count),
                })),
                label: key,
                borderColor:
                  key === "Scheme"
                    ? "#00b0f0"
                    : key === "Services"
                      ? "#a8b918"
                      : key === "Awareness"
                        ? "#1a3e77"
                        : "#328ba8",
                backgroundColor:
                  key === "Scheme"
                    ? "#00b0f0"
                    : key === "Services"
                      ? "#a8b918"
                      : key === "Awareness"
                        ? "#1a3e77"
                        : "#328ba8",
                fill: false,
                borderWidth: 1.5,
                borderSkipped: false,
              })),
          },
        } : ["Sakhis Providing Evidences Per Month"]?.includes(val?.title) ? {
          ...val,
          type: type?.sakhisProvidingEvidencesPerMonth,
          legend: Object.keys(barGroup)?.map((key) => ({
            name: key,
            color:
              key === "Scheme"
                ? "#00b0f0"
                : key === "Services"
                  ? "#a8b918"
                  : key === "Awareness"
                    ? "#1a3e77"
                    : "#328ba8",
          })),
          data: {
            labels: moment.monthsShort(),
            datasets: Object.keys(barGroup)
              ?.filter((key) =>
                select.sakhisProvidingEvidencesPerMonth === "All"
                  ? key
                  : key === select?.sakhisProvidingEvidencesPerMonth
              )
              ?.map((key, i) => ({
                data: barGroup[key]?.map((val) => ({
                  x: moment.monthsShort()[parseInt(val?.month) - 1],
                  y: Number(val?.sakhi_evidence_per_month_count),
                })),
                label: key,
                borderColor:
                  key === "Scheme"
                    ? "#00b0f0"
                    : key === "Services"
                      ? "#a8b918"
                      : key === "Awareness"
                        ? "#1a3e77"
                        : "#328ba8",
                backgroundColor:
                  key === "Scheme"
                    ? "#00b0f0"
                    : key === "Services"
                      ? "#a8b918"
                      : key === "Awareness"
                        ? "#1a3e77"
                        : "#328ba8",
                fill: false,
                borderWidth: 1.5,
                borderSkipped: false,
              })),
          },
        } : ["Average. Evidence Revenue Per Month"]?.includes(val?.title) ? {
          ...val,
          legend: Object.keys(barGroup)?.map((key) => ({
            name: key,
            color:
              key === "Scheme"
                ? "#00b0f0"
                : key === "Services"
                  ? "#a8b918"
                  : key === "Awareness"
                    ? "#1a3e77"
                    : "#328ba8",
          })),
          data: {
            labels: moment.monthsShort(),
            datasets: Object.keys(barGroup)
              ?.filter((key) => key === "Services")
              ?.map((key, i) => ({
                data: barGroup[key]?.map((val) => ({
                  x: moment.monthsShort()[parseInt(val?.month) - 1],
                  y: Number(val?.sakhi_revenue_per_month_count),
                })),
                label: key,
                borderColor:
                  key === "Scheme"
                    ? "#00b0f0"
                    : key === "Services"
                      ? "#a8b918"
                      : key === "Awareness"
                        ? "#1a3e77"
                        : "#328ba8",
                backgroundColor:
                  key === "Scheme"
                    ? "#00b0f0"
                    : key === "Services"
                      ? "#a8b918"
                      : key === "Awareness"
                        ? "#1a3e77"
                        : "#328ba8",
                fill: false,
                borderWidth: 1.5,
                borderSkipped: false,
              })),
          },
        } : ["Average Sakhi Revenue Per Month"]?.includes(val?.title) ? {
          ...val,
          type: type?.averageSakhiRevenuePerMonth,
          legend: Object.keys(barGroup)?.map((key) => ({
            name: key,
            color:
              key === "Scheme"
                ? "#00b0f0"
                : key === "Services"
                  ? "#a8b918"
                  : key === "Awareness"
                    ? "#1a3e77"
                    : "#328ba8",
          })),
          data: {
            labels: moment.monthsShort(),
            datasets: Object.keys(barGroup)
              ?.filter((key) =>
                select.averageSakhiRevenuePerMonth === "All"
                  ? key
                  : key === select?.averageSakhiRevenuePerMonth
              )
              ?.map((key, i) => ({
                data: barGroup[key]?.map((val) => ({
                  x: moment.monthsShort()[parseInt(val?.month) - 1],
                  y: Number(val?.avg_sakhi_revenue),
                })),
                label: key,
                borderColor:
                  key === "Scheme"
                    ? "#00b0f0"
                    : key === "Services"
                      ? "#a8b918"
                      : key === "Awareness"
                        ? "#1a3e77"
                        : "#328ba8",
                backgroundColor:
                  key === "Scheme"
                    ? "#00b0f0"
                    : key === "Services"
                      ? "#a8b918"
                      : key === "Awareness"
                        ? "#1a3e77"
                        : "#328ba8",
                fill: false,
                borderWidth: 1.5,
                borderSkipped: false,
              })),
          },
        } : val
      );
      setUpdateChartConfig(chartUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overallGraph,
    worstFiveBlockTransaction,
    topFiveBlockTransaction,
    topFiveBlockSakhi,
    comparisonAllData,
    comparisonSelect,
    active,
    type,
    select,
  ]);

  const handleBarChange = (title) => {
    if (["Beneficiaries Reached By Month"]?.includes(title)) {
      setType({ ...type, beneficiariesReachedByMonth: "bar" });
    } else if (["Remuneration Till Date"]?.includes(title)) {
      setType({ ...type, renumerationTillDate: "bar" });
    } else if (["Average Transactions Per Month"]?.includes(title)) {
      setType({ ...type, averageTransactionPerMonth: "bar" });
    } else if (["Evidence Per Month"]?.includes(title)) {
      setType({ ...type, evidencePerMonth: "bar" });
    } else if (["Average Sakhi Revenue Per Month"]?.includes(title)) {
      setType({ ...type, averageSakhiRevenuePerMonth: "bar" });
    } else if (["Sakhis Providing Evidences Per Month"]?.includes(title)) {
      setType({ ...type, sakhisProvidingEvidencesPerMonth: "bar" });
    } else if (["Comparison"]?.includes(title)) {
      setType({ ...type, comparsion: "pie" });
    }
  };
  const handleLineChange = (title) => {
    if (["Beneficiaries Reached By Month"]?.includes(title)) {
      setType({ ...type, beneficiariesReachedByMonth: "line" });
    } else if (["Remuneration Till Date"]?.includes(title)) {
      setType({ ...type, renumerationTillDate: "line" });
    } else if (["Average Transactions Per Month"]?.includes(title)) {
      setType({ ...type, averageTransactionPerMonth: "line" });
    } else if (["Evidence Per Month"]?.includes(title)) {
      setType({ ...type, evidencePerMonth: "line" });
    } else if (["Average Sakhi Revenue Per Month"]?.includes(title)) {
      setType({ ...type, averageSakhiRevenuePerMonth: "line" });
    } else if (["Sakhis Providing Evidences Per Month"]?.includes(title)) {
      setType({ ...type, sakhisProvidingEvidencesPerMonth: "line" });
    } else if (["Comparison"]?.includes(title)) {
      setType({ ...type, comparsion: "line" });
    }
  };
  const handleTopChange = (title) => {
    if (["5 Performing Blocks"]?.includes(title)) {
      setclicktopFiveBlockTransaction('top');
    } else if (["5 Best Performing Sakhis"]?.includes(title)) {
      setclicktopfiveBlockSakhi("top");
    }
  };
  const handleWorstChange = (title) => {
    if (["5 Performing Blocks"]?.includes(title)) {
      setclicktopFiveBlockTransaction('worst');
    } else if (["5 Best Performing Sakhis"]?.includes(title)) {
      setclicktopfiveBlockSakhi("worst");
    }
  };
  const handleSelectChange = (value, title) => {
    if (["Beneficiaries Reached By Month"]?.includes(title)) {
      setSelect({ ...select, beneficiariesReachedByMonth: value });
    } else if (["Remuneration Till Date"]?.includes(title)) {
      setSelect({ ...select, renumerationTillDate: value });
    } else if (["Average Transactions Per Month"]?.includes(title)) {
      setSelect({ ...select, averageTransactionPerMonth: value });
    } else if (["Evidence Per Month"]?.includes(title)) {
      setSelect({ ...select, evidencePerMonth: value });
    } else if (["Average Sakhi Revenue Per Month"]?.includes(title)) {
      setSelect({ ...select, averageSakhiRevenuePerMonth: value });
    } else if (["Sakhis Providing Evidences Per Month"]?.includes(title)) {
      setSelect({ ...select, sakhisProvidingEvidencesPerMonth: value });
    } else if (["Comparison"]?.includes(title)) {
      setSelect({ ...select, comparison: value });
    }
  };

  useEffect(() => {
    if (selectedState) {
      setSelectedLevel("district");
      if (Object.values(filterData).every((item) => item === "")) {
        setFilterData({
          state_id: states?.data?.find(val => val.name === selectedState)?.id,
          district_id: "",
          block_id: "",
          village_id: "",
          purpose_id: "",
          scheme_id: "",
          from: "",
          to: "",
          ngo_id: "",
          sakhi_id: "",
          fo_id: "",
        });
      }
      // handleFilterSearch()
    } else {
      setSelectedLevel("state");
      setFilterData({
        state_id: "",
        district_id: "",
        block_id: "",
        village_id: "",
        purpose_id: "",
        scheme_id: "",
        from: "",
        to: "",
        ngo_id: "",
        sakhi_id: "",
        fo_id: "",
      });
      // handleRestFilter()
    }
  }, [selectedState]);

  useEffect(() => {
    if ((selectedLevel === "district" && selectedState) || selectedState === "") fetchAllApis(filterData);
  }, [selectedLevel]);

  let filtersToDisplay = ["calendar_year", "state_id", "district_id", "block_id", "village_id", "purpose_id", "scheme_id", "from", "to", "year", "fo_id", "sakhi_id"];

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      <Box width="100%" height="100%">
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        // alignItems='center'
        >
          <Typography>
            <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
              Dashboard
            </span>
          </Typography>
          <Box className="space-x-2 ">
            <Button
              variant="contained"
              size="small"
              startIcon={<FilterList color="primary" />}
              onClick={() => setShowFilter(!showFilter)}
              // color="primary"
              style={{
                backgroundColor: "white",
                color: theme.palette.primary.main,
              }}
            >
              Filter
            </Button>
            <Button
              variant="contained"
              size="small"
              endIcon={<CloudDownload />}
              onClick={() => getCsvDownloadData(filterData)}
              color="primary"
            >
              Download Report
            </Button>
            <Button
              variant="contained"
              size="small"
              endIcon={<ArrowRightAlt />}
              onClick={() => navigate('/app/download-queue-list')}
              style={{
                backgroundColor: "white",
                color: theme.palette.primary.main,
              }}
            >
              Download Status
            </Button>
          </Box>
        </Box>
        <Divider style={{ maxWidth: "100%" }} />
        {showFilter ? <CommonFilter2
          filterData={filterData}
          showFilter={showFilter}
          setFilterData={setFilterData}
          handleFilterSearch={handleFilterSearch}
          handleRestFilter={handleRestFilter}
          filtersToDisplay={role_id == 1 ? [...filtersToDisplay, "ngo_id"] : filtersToDisplay}
        /> : null}
        <Box className="py-5">
          <Typography variant="caption" className="text-gray-500"></Typography>
        </Box>
        <Grid container>
          <Grid container item xs={12} sm={12} md={6} lg={6} className={classGrid ? `transform  translate-x-0 z-0 transition duration-500 delay-500  ease-in-out` : `transform  -translate-x-full z-0 transition duration-500 ease-in-out opacity-0 `} >
            <MapView
              mapData={mapAllData}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
            />
            {/* <img src={mapPng} className='w-full ' /> */}
          </Grid>
          <Grid container item
            xs={12} sm={12} md={6}
            lg={6} className={classGrid ? `bg-gray-50 transform  translate-x-0 z-0 transition duration-500 delay-100  ease-in-out` : `transform  translate-x-full z-0 transition duration-100 ease-in-out opacity-0 `}
          >
            <ScrollBar
              className="overflow-y-scroll"
              style={{ height: "500px" }}
            >
              <Grid container item xs={12} sm={12} md={12} lg={12}>
                {AdminDetailsBox?.map((detail, i) => (
                  <IndicatorsComponent
                    detail={detail}
                    i={i}
                    setActiveIndicator={setActiveIndicator}
                    indicatorData={indicatorData}
                    activeIndicator={activeIndicator}
                    chartLoading={indicatorLoading}
                    classN={classN}
                  />
                ))}
              </Grid>
            </ScrollBar>
          </Grid>
        </Grid>
        <Grid container>
          {updataChartConfig?.map((config, i) => (
            <Grid key={i}
              className={scroll ? `p-3 transform  translate-x-0 z-0 transition duration-500 delay-100  ease-in-out` : `transform  ${i % 2 === 0 ? '-translate-x-full' : 'translate-x-full'} z-0 transition duration-500 ease-in-out opacity-0`}
              item xs={12} sm={12} md={6} lg={6}>
              <Box className="w-full flex flex-col justify-between items-center  bg-white overflow-hidden     rounded shadow h-full">
                <Box className="w-full flex justify-between items-center mx-2 p-2 border-b">
                  <Box className="w-full flex justify-between items-center">
                    <Typography
                      variant="body2"
                      style={{ color: theme.palette.grey[800] }}
                    >
                      {["5 Performing Blocks", "5 Best Performing Sakhis",].includes(config?.title)
                        ? ["5 Performing Blocks"].includes(config?.title) &&
                          clicktopFiveBlockTransaction === "top"
                          ? "Top" + " " + config?.title
                          : ["5 Best Performing Sakhis"].includes(
                            config?.title
                          ) && clicktopFiveBlockSakhi === "top"
                            ? "Top" + " " + config?.title
                            : "Worst" + " " + config?.title
                        : config?.title}
                      {config?.logic && <Tooltip title={config?.logic}
                        TransitionComponent={Zoom}>
                        <IconButton>
                          <InfoOutlined style={{ color: theme.palette.grey[800] }} />
                        </IconButton>
                      </Tooltip>}
                    </Typography>
                    {["Comparison"].includes(config?.title) ? (
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          style: {
                            maxHeight: 400,
                            maxWidth: 200,
                          },
                        }}
                        className="bg-white w-64 h-8"
                        name={"comparison"}
                        onChange={(e) => {
                          setComparisonSelect(e.target.value);
                        }}
                        value={comparisonSelect ? comparisonSelect : null}
                        variant="outlined"
                        style={{
                          borderRadius: 10,
                          fontSize: 10,
                          border: "none",
                        }}
                        input={<OutlinedInput className="w-full border-none" />}
                        inputProps={{
                          style: {
                            fontSize: 10,
                            overflow: "scroll",
                            height: "50px",
                            border: "none",
                          },
                        }}
                        renderValue={(selected) => {
                          return (
                            <MenuItem value={selected}>{selected}</MenuItem>
                          );
                        }}
                      >
                        {AdminDetailsBox?.filter(item => !["Average Income of Sakhis", "Median Income of Sakhis"].includes(item?.label))?.map((val) => val.label)?.map(
                          (item, i) => (
                            <MenuItem
                              key={i}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    ) : null}
                    <Box className="flex justify-end items-center space-x-2">
                      {titleButton?.includes(config?.title) && (
                        <Box className="flex justify-start items-center">
                          <Box
                            onClick={() => handleBarChange(config?.title)}
                            className={
                              ["Beneficiaries Reached By Month"]?.includes(config?.title) &&
                                type?.beneficiariesReachedByMonth === "bar"
                                ? titleLeftButtonActiveStyle
                                : ["Remuneration Till Date"]?.includes(config?.title) &&
                                  type?.renumerationTillDate === "bar"
                                  ? titleLeftButtonActiveStyle
                                  : ["Average Transactions Per Month"]?.includes(config?.title) &&
                                    type?.averageTransactionPerMonth === "bar"
                                    ? titleLeftButtonActiveStyle
                                    : ["Evidence Per Month"]?.includes(config?.title) &&
                                      type?.evidencePerMonth === "bar"
                                      ? titleLeftButtonActiveStyle
                                      : ["Average Sakhi Revenue Per Month"]?.includes(config?.title) &&
                                        type?.averageSakhiRevenuePerMonth === "bar"
                                        ? titleLeftButtonActiveStyle
                                        : ["Sakhis Providing Evidences Per Month",]?.includes(config?.title) &&
                                          type?.sakhisProvidingEvidencesPerMonth === "bar"
                                          ? titleLeftButtonActiveStyle
                                          : ["Comparison"]?.includes(config?.title) &&
                                            type?.comparison === "pie"
                                            ? titleLeftButtonActiveStyle
                                            : titleLeftButtonDeactiveStyle
                            }
                          >
                            {["Comparison"].includes(config.title) ? (<PieChartOutlined className="" />)
                              : (<SignalCellularAlt className="" />)}
                          </Box>
                          <Box
                            onClick={() => handleLineChange(config?.title)}
                            className={
                              ["Beneficiaries Reached By Month"]?.includes(
                                config?.title
                              ) && type?.beneficiariesReachedByMonth === "line"
                                ? titleRightButtonActiveStyle
                                : ["Remuneration Till Date"]?.includes(config?.title) &&
                                  type?.renumerationTillDate === "line"
                                  ? titleRightButtonActiveStyle
                                  : ["Average Transactions Per onth"]?.includes(config?.title) &&
                                    type?.averageTransactionPerMonth === "line"
                                    ? titleRightButtonActiveStyle
                                    : ["Evidence Per Month"]?.includes(config?.title) &&
                                      type?.evidencePerMonth === "line"
                                      ? titleRightButtonActiveStyle
                                      : ["Average Sakhi Revenue Per Month"]?.includes(config?.title) &&
                                        type?.averageSakhiRevenuePerMonth === "line"
                                        ? titleRightButtonActiveStyle
                                        : ["Sakhis Providing Evidences Per Month",]?.includes(config?.title) &&
                                          type?.sakhisProvidingEvidencesPerMonth === "line"
                                          ? titleRightButtonActiveStyle
                                          : ["Comparison"]?.includes(config?.title) &&
                                            type?.comparison === "line"
                                            ? titleRightButtonActiveStyle : titleRightButtonDeactiveStyle
                            }
                          >
                            <Timeline className="" />
                          </Box>
                        </Box>
                      )}
                      {[
                        "5 Performing Blocks",
                        "5 Best Performing Sakhis",
                      ]?.includes(config?.title) && (
                          <Box className="flex justify-start items-center cursor-pointer">
                            <Box
                              onClick={() => handleTopChange(config?.title)}
                              className={
                                ["5 Performing Blocks"]?.includes(
                                  config?.title
                                ) && clicktopFiveBlockTransaction === "top"
                                  ? "border text-xs px- p-1 rounded-l-md bg-white hover:bg-gray-100 text-gray-500 "
                                  : ["5 Best Performing Sakhis"]?.includes(
                                    config?.title
                                  ) && clicktopFiveBlockSakhi === "top"
                                    ? "border text-xs px- p-1 rounded-l-md bg-white hover:bg-gray-100 text-gray-500 "
                                    : "border text-xs px- p-1 rounded-l-md bg-gray-100 hover:bg-white  text-gray-500"
                              }
                            >
                              Top
                            </Box>
                            <Box
                              onClick={() => handleWorstChange(config?.title)}
                              className={
                                ["5 Performing Blocks"]?.includes(
                                  config?.title
                                ) &&
                                  clicktopFiveBlockTransaction === "worst"
                                  ? "border text-xs px- p-1 rounded-r-md bg-white hover:bg-gray-100 text-gray-500 "
                                  : ["5 Best Performing Sakhis"]?.includes(
                                    config?.title
                                  ) && clicktopFiveBlockSakhi === "worst"
                                    ? "border text-xs px- p-1 rounded-r-md bg-white hover:bg-gray-100 text-gray-500 "
                                    : "border text-xs px- p-1 rounded-r-md bg-gray-100 hover:bg-white hover:text-gray-500 text-gray-500"
                              }
                            >
                              Worst
                            </Box>
                          </Box>
                        )}
                    </Box>
                  </Box>
                  {["Beneficiaries Reached By Month"]?.includes(
                    config?.title
                  ) && (
                      <Box className="w-6/12 flex justify-between items-center space-x-2">
                        <button
                          onClick={() => {
                            handleSelectChange("All", config?.title);
                            setActive({
                              person: true,
                              gender: false,
                              shg: false,
                            });
                          }}
                          className={
                            active.person
                              ? "w-3/4 p-1 rounded-xl text-primary border border-primary text-xs"
                              : "w-3/4 p-1 rounded-xl border border-gray-200 text-xs"
                          }
                        >
                          Purpose
                        </button>
                        <button
                          onClick={() => {
                            handleSelectChange("All", config?.title);
                            setActive({
                              person: false,
                              gender: true,
                              shg: false,
                            });
                          }}
                          className={
                            active.gender
                              ? "w-3/4 p-1 rounded-xl text-primary border border-primary text-xs"
                              : "w-3/4 p-1 rounded-xl border border-gray-200 text-xs"
                          }
                        >
                          Gender
                        </button>
                        <button
                          onClick={() => {
                            handleSelectChange("All", config?.title);
                            setActive({
                              person: false,
                              gender: false,
                              shg: true,
                            });
                          }}
                          className={
                            active.shg
                              ? "w-3/4 p-1 rounded-xl text-primary border border-primary text-xs"
                              : "w-3/4 p-1 rounded-xl border border-gray-200 text-xs"
                          }
                        >
                          SHG Status
                        </button>
                      </Box>
                    )}
                </Box>
                {[
                  "Beneficiaries Reached By Month",
                  "Remuneration Till Date",
                  "Average Transactions Per Month",
                  "Evidence Per Month",
                  // "Average. Evidence Revenue Per Month",
                  "Average Sakhi Revenue Per Month",
                  "Sakhis Providing Evidences Per Month",
                ]?.includes(config?.title) && (
                    <Box className="w-full p-2 flex justify-between items-center space-x-2 h-14">
                      <Box className="flex justify-center items-center px-3">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          {config?.legend
                            ?.concat({ name: "All" })
                            ?.reverse()
                            ?.map((item) => (
                              <FormControlLabel
                                checked={
                                  ["Beneficiaries Reached By Month"]?.includes(
                                    config?.title
                                  )
                                    ? select?.beneficiariesReachedByMonth === item?.name
                                    : ["Remuneration Till Date"]?.includes(config?.title)
                                      ? select?.renumerationTillDate === item?.name
                                      : ["Average Transactions Per Month"]?.includes(config?.title)
                                        ? select?.averageTransactionPerMonth === item?.name
                                        : ["Evidence Per Month"]?.includes(config?.title)
                                          ? select?.evidencePerMonth === item?.name
                                          : ["Average Sakhi Revenue Per Month",]?.includes(config?.title)
                                            ? select?.averageSakhiRevenuePerMonth === item?.name
                                            : ["Sakhis Providing Evidences Per Month",]?.includes(config?.title)
                                              ? select?.sakhisProvidingEvidencesPerMonth === item?.name
                                              : item?.name === "Services"
                                }
                                value={item?.name}
                                control={
                                  <Box
                                    className={
                                      ["Beneficiaries Reached By Month"]?.includes(config?.title) &&
                                        select?.beneficiariesReachedByMonth === item?.name
                                        ? legendActiveColor
                                        : ["Remuneration Till Date"]?.includes(config?.title) &&
                                          select?.renumerationTillDate === item?.name
                                          ? legendActiveColor
                                          : ["Average Transactions Per Month"]?.includes(config?.title) &&
                                            select?.averageTransactionPerMonth === item?.name
                                            ? legendActiveColor
                                            : ["Evidence Per Month"]?.includes(config?.title) &&
                                              select?.evidencePerMonth === item?.name
                                              ? legendActiveColor
                                              : ["Average Sakhi Revenue Per Month"]?.includes(config?.title) &&
                                                select?.averageSakhiRevenuePerMonth === item?.name
                                                ? legendActiveColor
                                                : ["Sakhis Providing Evidences Per Month"]?.includes(config?.title) &&
                                                  select?.sakhisProvidingEvidencesPerMonth === item?.name
                                                  ? legendActiveColor : legendDeactiveColor
                                    }
                                    size="small"
                                    onClick={() =>
                                      handleSelectChange(
                                        item?.name,
                                        config?.title

                                      )}
                                    color="primary"
                                  >
                                    {item?.name}
                                  </Box>
                                }
                                style={{ fontSize: "10px" }}
                              />
                            ))}
                        </RadioGroup>
                      </Box>
                    </Box>
                  )}
                {["Comparison"].includes(config.title) ? (
                  <Box className="w-full p-2 flex justify-between items-center">
                    <Box className=" p-2 flex justify-between items-center space-x-2 h-14">
                      {type?.comparsion === 'line' && <Box className="flex justify-center items-center px-3">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          {config?.legend?.concat({ name: "All" })
                            ?.reverse()?.map((item) => (
                              <FormControlLabel
                                checked={
                                  ["Comparison"]?.includes(
                                    config?.title
                                  )
                                    ? select?.comparison === item?.name
                                    : item?.name === "Assam"
                                }
                                value={item?.name}
                                control={
                                  <Box
                                    className={
                                      ["Comparison"]?.includes(
                                        config?.title
                                      ) && select?.comparison ===
                                        item?.name
                                        ? legendActiveColor
                                        : legendDeactiveColor
                                    }
                                    size="small"
                                    onClick={() =>
                                      handleSelectChange(
                                        item?.name,
                                        config?.title
                                      )
                                    }
                                    color="primary"
                                  >
                                    {item?.name}
                                  </Box>
                                }
                                style={{ fontSize: "10px" }}
                              />
                            ))}
                        </RadioGroup>
                      </Box>}
                    </Box>
                    <Box className="flex justify-end items-end space-x-2">
                      <Typography
                        variant="subtitle2"
                        style={{ color: theme?.palette?.grey[500] }}
                      >
                        {comparisonSelect}
                      </Typography>
                      <Typography variant="body2" style={{ fontWeight: 600 }}>
                        {comparisonAllData?.total || 0}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {[
                  "5 Performing Blocks",
                  "5 Best Performing Sakhis",
                ]?.includes(config?.title) ? (
                  <Box className="w-full h-14  p-2 flex justify-between items-center space-x-2">
                    <Box className="w-40">

                      {["Comparison"].includes(config.title) ? (
                        <></>
                      ) : null}
                    </Box>
                    {["5 Performing Blocks", "5 Best Performing Sakhis",].includes(config.title) ? (
                      (topFiveBlockSakhi && topFiveBlockTransaction && worstFiveBlockTransaction && worstFiveSakhiTransaction) ? <TopWorstBlock clicktopFiveBlockSakhi={clicktopFiveBlockSakhi} clicktopFiveBlockTransaction={clicktopFiveBlockTransaction} worstFiveBlockTransaction={worstFiveBlockTransaction} worstFiveSakhiTransaction={worstFiveSakhiTransaction} topFiveBlockSakhi={topFiveBlockSakhi} topFiveBlockTransaction={topFiveBlockTransaction} config={config} /> : null
                    ) : null}
                  </Box>
                ) : null}
                <Box className="w-full  overflow-hidden p-2 flex justify-center items-center">
                  {[
                    "5 Performing Blocks",
                    "5 Best Performing Sakhis",
                  ]?.includes(config?.title) ? (
                    (topFiveBlockSakhi && topFiveBlockTransaction) ?
                      <BlockComponent worstFiveBlockTransaction={worstFiveBlockTransaction} worstFiveSakhiTransaction={worstFiveSakhiTransaction?.reverse()} clicktopFiveBlockSakhi={clicktopFiveBlockSakhi} clicktopFiveBlockTransaction={clicktopFiveBlockTransaction} topFiveBlockSakhi={topFiveBlockSakhi?.reverse()} topFiveBlockTransaction={topFiveBlockTransaction} config={config} />
                      : null
                  ) : (
                    <LineChart
                      chartType={config?.type}
                      chartData={config?.data}
                      chartOptions={config?.options}
                    />
                  )}
                </Box>
                <Box className="w-full p-2 mx-2 border-t flex justify-around items-center space-x-1">
                  {config?.legend?.map((leg, i) => (
                    <Box key={i}
                      className="flex justify-center items-center space-x-1"
                    >
                      <span
                        className="w-2 h-2 rounded"
                        style={{ backgroundColor: leg.color }}
                      ></span>
                      <Typography
                        variant="caption"
                        style={{ color: theme.palette.grey[800] }}
                      >
                        {leg?.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {/* )} */}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default MainDashboard;
