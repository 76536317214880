import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);


const LineChart = ({ chartType, chartData, chartOptions }) => {
	const chartRef = React.createRef();
	const [myChart, setmyChart] = useState(null);

	useEffect(() => {
		let myChart1 = myChart;
		const type = chartType
		const data = chartData;
		const options = chartOptions
		const config = {
			type,
			data,
			options
		}
		if (typeof (myChart1) != "undefined") {
			myChart1?.destroy();
		}
		const ctx = chartRef.current.getContext("2d");
		myChart1 = (new Chart(ctx, config))
		setmyChart(myChart1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartType, chartData, chartOptions])

	return (
		<div className='w-full h-full max-h-[300px]'>
			<canvas
				id="myChart"
				ref={chartRef}
				height={200}
			/>
		</div>
	)
}

export default React.memo(LineChart);