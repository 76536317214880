import { Box, Button, Divider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StickyHeadTable from "src/components/CommonComponents/Table";
import LoadingBox from "src/components/LoadingBox";
import { useMemo } from "react";
import { generateSerialNo } from "src/utils";
import { CloudDownload, SendSharp } from "@material-ui/icons";
import moment from "moment";
import { fetchWrapper } from "src/services/http_requests";
import { toast } from "react-toastify";
import { paramsUrl } from "src/utils/halper";

const Base_Url = process.env.REACT_APP_API_URL;

const columns = [
    { id: "sno", label: "S.No" },
    { id: "status", label: "Status" },
    { id: "emailStatus", label: "Email Status" },
    { id: "createdAt", label: "Created At" },
    { id: "file", label: "File" },
    { id: "sendToEmail", label: "Send to Email" },
];
function createData(sno, status, emailStatus, createdAt, file, sendToEmail) {
    return { sno, status, emailStatus, createdAt, file, sendToEmail };
}
const downloadStatus = {
    1: 'Pending',
    2: 'Failed',
    3: 'Success',
}

const DownloadQueue = () => {

    const pageInfo = localStorage.getItem("pageInfo");
    const [total, setTotal] = useState(0);
    const [page, setPage] = React.useState(() => pageInfo ? parseInt(pageInfo) : 1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queueList, setQueueList] = useState({});
    const [loading, setLoading] = useState([]);

    const fetchQueueList = async (filter) => {
        setLoading(true);
        try {
            let data = await fetchWrapper.get(`${Base_Url}/download_sheet/list${paramsUrl(filter)}`);
            setQueueList(data)
        } catch (error) {
            console.error(error);
        } finally { setLoading(false); }
    }

    useEffect(() => {
        fetchQueueList({ page });
    }, [page]);

    const sentEmail = async (userDetail, queryId, csvUrl) => {
        try {
            const response = await fetchWrapper.post(Base_Url + '/download_sheet/mail', {
                "id": queryId,
                "name": userDetail.name,
                "email": userDetail.email,
                "url": csvUrl
            });
            toast.success(response.message);
        } catch (error) {
            console.error(error);
        }
    }

    const queueRows = useMemo(() => {
        if (queueList?.total) {
            setTotal(queueList?.total);
            localStorage.setItem("totalData", queueList?.total);
        } else {
            if (parseInt(page) === 1) {
                setTotal(queueList?.data?.length);
                localStorage.setItem("totalData", queueList?.data?.length);
            } else setTotal(localStorage.getItem("totalData"));
        }
        return queueList?.data?.map((item, i) => createData(
            generateSerialNo(i, page),
            downloadStatus[item.download_status],
            downloadStatus[item.email_status],
            moment(item.created_at).format('DD-MM-YYYY hh:mm a'),
            item.download_status === "3" ? <CloudDownload
                className="cursor-pointer"
                color="primary"
                disabled={item.download_status !== "3"}
                onClick={() => window.open(item?.url, "_blank")}
            /> : '',
            <Button
                variant="contained"
                size="small"
                endIcon={<SendSharp />}
                onClick={() => {
                    if (item.download_status !== "3") return;
                    sentEmail(item.user, item.id, item.url)
                }}
                color="primary"
                disabled={item.download_status !== "3"}
            >
                Send
            </Button>
        ));
    }, [queueList]);

    return (
        <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
            {loading ? (
                <LoadingBox />
            ) : (
                <Box width="100%" height="100%">
                    <Box
                        pb={2}
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Typography>
                            <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
                                Report Download Status
                            </span>
                        </Typography>
                    </Box>
                    <Divider style={{ maxWidth: "100%" }} />

                    <StickyHeadTable
                        columns={columns}
                        rows={queueRows}
                        loading={loading}
                        total={total}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </Box>
            )}
        </Box>
    )
}

export default DownloadQueue;